.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 2vw;
    border-radius: 20px;
    margin-left: 1vw;
    margin-right: 1vw;
}

.text {
    font-size: 2rem;
    font-family: sans-serif;
    color: black;
    padding: 1vw;
    text-align: center;
    text-wrap: wrap;
    max-width: 90vw;
}

@media (max-width: 800px) {
    .text {
        font-size: 1rem;
    }
}

.textSmall {
    font-size: 1rem;
    font-family: sans-serif;
    color: black;
    padding: 20px;
    text-align: center;
}