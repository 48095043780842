.styledButton {
    display: flex;
    justify-content: center; 
    align-items: center;
    border: 1px solid #0073b1;
    padding: 1vw; 
    text-align: center;
    color: #ffffff; 
    border-radius: 15px;
    background-color: #0b66c2;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    font-family: sans-serif;
    width: 10vw;
    height: 5vh;
    font-size: 1rem;
    position: fixed;
    top: 15vh;
    right: 1vw;
    z-index: 1000;
    min-width: 80px;
  }
  
  .styledButton:hover {
    background-color: #005a90;
  }
  
  @media (max-width: 800px) {
    .styledButton {
      top: 3vh;
    }
  }