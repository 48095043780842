.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: white;
  padding: 1vw;
  border-radius: 1vw;
  margin-left: 1vw;
  margin-right: 1vw;
}

.nextRecruitLogo {
  width: 50vw;
  height: auto;
}

@media (max-width: 800px) {
  .nextRecruitLogo {
    width: 90vw;
  }
}

.body {
  display: flex;
  padding-left: 15vw;
  padding-right: 15vw;
  line-height: 1.5;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
}

@media (max-width: 800px) {
    .body {
        padding-left: 5vw;
        padding-right: 5vw;
    }
  }

.paragraph {
  font-size: 2rem;
  font-family: sans-serif;
  color: black;
  padding: 1vw;
  text-align: center;
}

.img {
  width: 50vw;
  height: auto;
  justify-self: center;
}

.img_small {
  width: auto;
  height: 90vh;
  justify-self: center;
  padding: 1vw;
}

.sidebyside {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vw;
}


@media (max-width: 800px) {
    .img {
      width: 90vw;
    }
    .sidebyside {
      flex-direction: column;
    }
    .img_small {
      width: 80vw;
      height: auto;
    }
    .paragraph {
      font-size: 1.5rem;
    }
  }

/* Overlay that covers the entire screen */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Close button */
.close-button {
  position: relative;
  top: 1vw;
  right: 1vw;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0073b1;
  padding: 1vw;
  margin-bottom: 1vh;
  text-align: center;
  color: #ffffff;
  border-radius: 15px;
  background-color: #0b66c2;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  font-family: sans-serif;
  width: 10vw;
  height: 5vh;
  min-width: 150px;
  font-size: 1rem;
}

.button:hover {
  background-color: #005a90;
}


