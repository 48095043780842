.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    width: 90vw;
    padding: 1vh;
}

.right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1vw;
    height: 100%;
}

.left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1vw;
    height: 100%;
}

.next_app_dev_logo {
    height: 8vh;
    width: auto;
}

.logo {
    height: 8vh;
    width: auto;
}

.navbar_button {
    align-content: center;
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: 1vw;
    text-decoration: none;
    color:black;
}

.navbar_button:hover {
    background-color: #0b66c2;
    color: #fff;
    transition: all 0.2s ease-out;
    text-align: center;
}